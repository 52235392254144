import { Component, OnInit } from '@angular/core';
import { JobService } from '../job.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.css']
})
export class AddJobComponent implements OnInit {

  Institutions = [];  
  Cities = [];
  Jobfunctions = [];
  Experiences = [];
  Studies = [];
  Contracts = [];
  AddForm: FormGroup;
  loading:boolean = true;

  constructor(private fb: FormBuilder, private jobService: JobService, private router:Router, private toastr: ToastrService) {
    this.createForm();
  }

  createForm() {
    this.AddForm = this.fb.group({
      name: ['', Validators.required ],
      description: ['', Validators.required ],
      institution_id: ['', Validators.required ],
      contract_id: ['', Validators.required ],
      study_id: ['', Validators.required ],
      jobfunction_id: ['', Validators.required ],
      city_id: ['', Validators.required ],
      experience_id: ['', Validators.required ],
      missions: ['', Validators.required ],
      skills: ['', Validators.required ],
      active: ['1', Validators.required ]
    });
  }

  ngOnInit() {
    
    this.jobService.getInstitutions()
      .subscribe(
        res => this.Institutions = res.data,
        err => console.log(err)
      )
    
    this.jobService.getCities()
      .subscribe(
        res => this.Cities = res.data,
        err => console.log(err)
      )
    
    this.jobService.getJobFunctions()
      .subscribe(
        res => this.Jobfunctions = res.data,
        err => console.log(err)
      )
    
    this.jobService.getExperiences()
      .subscribe(
        res => this.Experiences = res.data,
        err => console.log(err)
      )
    
    this.jobService.getStudies()
      .subscribe(
        res => this.Studies = res.data,
        err => console.log(err)
      )
    
    this.jobService.getContracts()
      .subscribe(
        res => {  this.Contracts = res.data
                  this.loading = false;
                },
        err => console.log(err)
      ) 
  }

  AddJob() {
    this.AddForm.value.active = 1;
    this.jobService.AddJob(JSON.stringify(this.AddForm.value))
    .subscribe(
      res => {res.data,
      this.toastr.success("Offre d'emploi est enregistré avec succès"),
      this.router.navigate(['/jobs'])
      },
      err => console.log(err)
    )
  }
}