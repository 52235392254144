import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer} from '@angular/core';
import{ RoleService } from '../role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.css']
})
export class ListRoleComponent implements OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};    
  @Input() role_id: string;
  @Input() role_title: string;
  loading:boolean = true;

  constructor(private route: ActivatedRoute, private router:Router, private roleService: RoleService, private toastr: ToastrService, private renderer:Renderer) { }

  ngOnInit() {
    this.dtOptions = {
      ajax: (dataTablesParameters: any, callback) => {
        this.roleService.GetRoles().subscribe(res => {
            callback({data: res.data});
            this.loading = false;
        });
      },
      columns: [
      {
        title: 'Name',
        data: null
      },{
        title: 'Date de creation',
        data: 'created'
      },{
        title: 'Date de modification',
        data: 'modified'
      },{     
        title: 'Action',
        data: null
      }],
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      stateSave: true,
      buttons: [
        {
            extend: 'print',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-print"></i> Imprimer',
            exportOptions: {
                columns: [1, 2, 3]
            }
        },
        {
            extend: 'excel',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-download"></i> Exporter en csv',
            exportOptions: {
                columns: [1, 2, 3]
            }
        }
      ],
      columnDefs: [
          {
              targets: [0],
              visible: true,
              data: 'action',
              render: function(data, type, full, meta) {
              return '<td><a class="EditRole" style="cursor: pointer;">'+data.name+'</a></td>'
              }
          },
          {
              targets: [3],
              visible: true,
              data: 'action',
              render: function(data, type, full, meta) {
               return '<div style="text-align: right;"<td style="text-align: right;"><a class="btn btn-sm btn-warning btn-icon EditRole" style="cursor: pointer;width: auto;font-size: 12px;"><i class="fa fa-unlock" style="padding-right: 5px;"></i> Gérer les permissions</a><a class="btn btn-sm btn-danger btn-icon RemoveRole" style="cursor: pointer;"><i class="fa fa-trash"></i></a></td></div>'
              }
          }
      ],
      rowCallback: (row: Node, data: any | Object, index: number) => {
          const self = this;
          const EditRole = $('td', row).find('a.EditRole');
          if (EditRole) {
              EditRole.unbind('click');
              EditRole.bind('click', () => {
                  this.router.navigate(['/roles/edit/'+data.id])
              });
          }
          const RemoveRole = $('td', row).find('a.RemoveRole');
          if (RemoveRole) {
              RemoveRole.unbind('click');
              RemoveRole.bind('click', () => {
                  this.showDeleteModal(data.id, data.name)
              });
          }
          return row;
      },
      responsive: true,
      language: {
          lengthMenu: 'Afficher _MENU_ enregistrements par page',
          zeroRecords: 'Aucun offre disponible',
          info: '_START_ à _END_ sur un total de _TOTAL_ enregistrements',
          infoEmpty: 'Aucun offre disponible',
          infoFiltered: '(filtré(s) sur _MAX_ enregistrements)',
          paginate: {
              first:      'Premier',
              last:       'dernier',
              next:       'Suivant',
              previous:   'Precedent'
          },
          search: '_INPUT_',
          searchPlaceholder: 'Recherche',

      }
    };
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();
  }  
  
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('#name').on('keyup change', function () {
          dtInstance.columns([0]).search(this['value']).draw();
      })    
    });  
  }

  DeleteRole(id) {
    this.roleService.deleteRole(id).subscribe(
      res => {console.log(res.data),
        this.ngOnInit();
        document.getElementById("close_modal").click();
        this.toastr.success("Role est supprimé avec succès")
      },
      err => console.log(err)
    )
    this.ngOnInit();
    document.getElementById("close_modal").click();
    this.toastr.success("Role est supprimé avec succès")  
    this.router.navigate(['/roles'])
  }

  showDeleteModal(id,title): void {
    this.role_id = id;
    this.role_title = title;
    document.getElementById("show_modal").click();
  }

}
