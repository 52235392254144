import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(private authService: AuthService,
    private router: Router) { }

  // canActivate(): boolean {
  //   const expectedRole = this.route.data.expectedRole;
  //   if (this.authService.loggedIn()) {
  //     // console.log('true')
  //     return true
  //   } else {
  //     // console.log('false')            
  //     this.router.navigate(['/login'])
  //     return false
  //   }
  // }


  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.loggedIn()) {
        this.router.navigate(['login']);
        return false;
    }else{
      // this will be passed from the route config
      // on the data property
      const expectedRole = route.data.expectedRole;    
      const token = localStorage.getItem('token');
      // decode the token to get its payload
      const tokenPayload = decode(token);
      const roles = expectedRole.find(x => x.role_id == tokenPayload.role_id);
      if (!roles) {
          this.router.navigate(['error']);
          return false;
      }
    }
    return true;
  }  
}