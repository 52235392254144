import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../../auth.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  user:any = {};
  Roles = [];
  Actives = [];
  EditForm: FormGroup;
  loading:boolean = true;

  constructor(private route: ActivatedRoute,private fb: FormBuilder, private userService: UserService, private authService:AuthService, private router:Router, private toastr: ToastrService) {
    this.createForm();
  }

  createForm() {
    this.EditForm = this.fb.group({
      username: ['', Validators.required ],
      role_id: ['', Validators.required ],
      active: ['', Validators.required ]
      // password: ['', Validators.required ]
    });
  }

  ngOnInit() {
    
    type Actives = Array<{id: number, name: string}>;
    this.Actives = [
        {id: 0, name: 'Inctive'},
        {id: 1, name: 'Active'}
    ];

    this.route.params.subscribe(params => {
      this.userService.getUser(params['id']).subscribe(res => {
        this.user = res.data;
        this.loading = false;
      });
    });
 
    this.userService.getRoles()
      .subscribe(
        res => this.Roles = res.data,
        err => console.log(err)
      )
  }

  EditUser(id) {
    this.userService.EditUser(JSON.stringify(this.EditForm.value), id)
    .subscribe(
      res => {res.data,
      this.toastr.success("Utilisateur est modifié avec succès"),
      this.router.navigate(['/users'])
      },
      err => console.log(err)
    )
  } 
}
