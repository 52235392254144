import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer} from '@angular/core';
import{ JobService } from '../job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-list-job',
  templateUrl: './list-job.component.html',
  styleUrls: ['./list-job.component.css']
})

export class ListJobComponent implements OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  Institutions = []; 
  Cities = [];
  Jobfunctions = [];
  jobs = [];  
  JobsDetails = [];  
  dtOptions: any = {};    
  @Input() job_id: string;
  @Input() job_title: string;
  job_data = [];
  token: any
  loading:boolean = true;

  constructor(private route: ActivatedRoute, private router:Router, private jobService: JobService, private toastr: ToastrService, private renderer:Renderer, private auth:AuthService) {
  }

  ngOnInit(): void {    

    this.jobService.getInstitutions().subscribe(
      res => this.Institutions = res.data,
      err => console.log(err)
    )

    this.jobService.getCities().subscribe(
      res => this.Cities = res.data,
      err => console.log(err)
    )

    this.jobService.getJobFunctions().subscribe(
      res => this.Jobfunctions = res.data,
      err => console.log(err)
    )
      
    this.dtOptions = {
      // ajax: 'http://localhost/rh/Server/api/jobs/Details',
      ajax: (dataTablesParameters: any, callback) => {
        this.jobService.getJobsDetails().subscribe(res => {
            callback({data: res.data});
            this.loading = false;
        });
      },
      columns: [
      {
        title: 'Poste',
        data: null
      }, {
        title: 'Établissement',
        data: 'institution.name'
      }, {
        title: 'Fonction',
        data: 'jobfunction.name'
      }, {
        title: 'Contrat',
        data: 'contract.name'
      }, {
        title: 'Ville',
        data: 'city.name'
      }, {
        title: "Niveau d'expérience",
        data: 'experience.name',
        class: 'none'
      }, {
        title: "Niveau d'étude",
        data: 'study.name',
        class: 'none'
      }, {
        title: "Description",
        data: 'description',
        class: 'none'
      }, {
        title: 'Date de creation',
        data: 'created'
      },{     
        title: 'Action',
        data: null
      }],
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      stateSave: true,
      buttons: [
        {
            extend: 'print',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-print"></i> Imprimer',
            exportOptions: {
                columns: [1, 2, 3, 4, 5, 6, 7, 8]
            }
        },
        {
            extend: 'excel',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-download"></i> Exporter en csv',
            exportOptions: {
                columns: [1, 2, 3, 4, 5, 6, 7, 8]
            }
        }
      ],
      createdRow: function(row, data, dataIndex) {
      // $compile(angular.element(row).contents())($scope);
      },
      columnDefs: [
        {
            targets: [0],
            visible: true,
            data: 'action',
            render: function(data, type, full, meta) {
             return '<td><a class="GetJob" style="cursor: pointer;">'+data.name+'</a></td>'
            }
        },
        {
            targets: [9],
            visible: true,
            data: 'action',
            render: function(data, type, full, meta, jobs_edit= "'jobs_edit'", jobs_delete= "'jobs_delete'") {
             return '<div style="text-align: right;"<td style="text-align: right;"><a class="btn btn-sm btn-success btn-icon GetJob" style="cursor: pointer;"><i class="fa fa-eye"></i></a><a class="btn btn-sm btn-warning btn-icon EditJob" *ngxPermissionsOnly="['+jobs_edit+']" style="cursor: pointer;"><i class="fa fa-edit"></i></a><a class="btn btn-sm btn-danger btn-icon RemoveJob" *ngxPermissionsOnly="['+jobs_delete+']" style="cursor: pointer;"><i class="fa fa-trash"></i></a></td></div>'
            }
        }
      ],
      rowCallback: (row: Node, data: any | Object, index: number) => {
          const self = this;
          const GetJob = $('td', row).find('a.GetJob');
          if (GetJob) {
              GetJob.unbind('click');
              GetJob.bind('click', () => {
                  this.router.navigate(['/jobs/get/'+data.id])
              });
          }
          const EditJob = $('td', row).find('a.EditJob');
          if (EditJob) {
              EditJob.unbind('click');
              EditJob.bind('click', () => {
                  this.router.navigate(['/jobs/edit/'+data.id])
              });
          }
          const RemoveJob = $('td', row).find('a.RemoveJob');
          if (RemoveJob) {
              RemoveJob.unbind('click');
              RemoveJob.bind('click', () => {
                  this.showDeleteModal(data.id, data.name, data)
              });
          }
          return row;
      },
      responsive: true,
      language: {
          lengthMenu: 'Afficher _MENU_ enregistrements par page',
          zeroRecords: 'Aucun offre disponible',
          info: '_START_ à _END_ sur un total de _TOTAL_ enregistrements',
          infoEmpty: 'Aucun offre disponible',
          infoFiltered: '(filtré(s) sur _MAX_ enregistrements)',
          paginate: {
              first:      'Premier',
              last:       'dernier',
              next:       'Suivant',
              previous:   'Precedent'
          },
          search: '_INPUT_',
          searchPlaceholder: 'Recherche',

      }
    };
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();
  }
  
  
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('#name').on('keyup change', function () {
          dtInstance.columns([0]).search(this['value']).draw();
      })  
      $('#institution').on('keyup change', function () {
          dtInstance.columns([1]).search(this['value']).draw();
      })
      $('#jobfunction').on('keyup change', function () {
          dtInstance.columns([2]).search(this['value']).draw();
      })   
      $('#city').on('keyup change', function () {
          dtInstance.columns([4]).search(this['value']).draw();
      })    
    });  
    
    // this.renderer.listenGlobal('document', 'click', (event) => {
    //   if (event.target.hasAttribute("view-person-id")) {
    //     this.router.navigate(["/person/" + event.target.getAttribute("view-person-id")]);
    //   }
    // });
  }

  test(){
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  showDeleteModal(id,title, data): void {
    this.job_id = id;
    this.job_title = title;
    this.job_data = data;
    document.getElementById("show_modal").click();
  }

  DeleteJob(id, data) {   
    data.active = 0;
    this.jobService.EditJob(data, id).subscribe(
      res => {
        this.ngOnInit();
        document.getElementById("close_modal").click();
        this.toastr.success("Offre d'emploi est supprimé avec succès")
        this.router.navigate(['/jobs'])
      },
      err => console.log(err)
    )
  }
  
}