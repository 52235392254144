import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CondidatureService {

  // private Url = "http://localhost/rh/Server/api";
  private Url = "https://rh.leonafricain.ma/Server/api";

  constructor(private http: HttpClient) { }

  getCondidatures() {
    return this.http.get<any>(this.Url+"/condidatures", httpOptions)
  }
  AddCondidature(condidature){
    return this.http.post<any>(this.Url+"/condidatures/add", condidature, httpOptions)
  }
  getCondidature(id){
    return this.http.get<any>(this.Url+"/condidatures/"+id, httpOptions)
  }
  getCondidaturesDetails(){
    return this.http.get<any>(this.Url+"/condidatures/Details", httpOptions)
  }
  getCondidatureDetails(id=null){
    return this.http.get<any>(this.Url+"/condidatures/Details/"+id, httpOptions)
  }
  getCondidatureByJob(job_id){
    return this.http.get<any>(this.Url+"/condidatures/getByJob/"+job_id, httpOptions)
  }  
  EditCondidature(condidature, id){
    return this.http.post<any>(this.Url+"/condidatures/edit/"+id, condidature, httpOptions)
  }
  deleteCondidature(id){
    return this.http.post<any>(this.Url+"/condidatures/delete/"+id, httpOptions)
  }
  getExperiences(){
    return this.http.get<any>(this.Url+"/experiences", httpOptions)
  }
  getStudies(){
    return this.http.get<any>(this.Url+"/studies", httpOptions)
  }
  getJobs() {
    return this.http.get<any>(this.Url+"/jobs", httpOptions)
  }
  getJobsByStatut(statut) {
    return this.http.get<any>(this.Url+"/jobs/byStatut/"+statut, httpOptions)
  }
  uploadData(url,data){
    return this.http.post<any>(url, data, httpOptions)
  }

}