import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../../auth.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  Roles = [];
  AddForm: FormGroup;
  constructor(private fb: FormBuilder, private userService: UserService, private authService:AuthService, private router:Router, private toastr: ToastrService) {
    this.createForm();
  }

  createForm() {
    this.AddForm = this.fb.group({
      username: ['', Validators.required ],
      role_id: ['', Validators.required ],
      password: ['', Validators.required ],
      active: ['1', Validators.required ]
    });
  }

  ngOnInit() {
    this.userService.getRoles()
      .subscribe(
        res => this.Roles = res.data,
        err => console.log(err)
      )
  }

  AddUser() {
    // console.log(this.registerUserData);
    this.AddForm.value.statut = 1;
    this.authService.registerUser(JSON.stringify(this.AddForm.value))
    .subscribe(
      res => {console.log(res.data),
      // localStorage.setItem('token', res.data.token),
      this.toastr.success('Utilisateur enregistré avec succès !'),
      this.router.navigate(['/users'])
      },
      err => console.log(err)
    ) 
  }
}
