import { Component, OnInit } from '@angular/core';
import { JobService } from '../job.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.css']
})
export class EditJobComponent implements OnInit {

  Institutions = [];  
  Cities = [];
  Jobfunctions = [];
  Experiences = [];
  Studies = [];
  Contracts = [];
  EditForm: FormGroup;
  job:any = {};
  loading:boolean = true;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private jobService: JobService, private router:Router, private toastr: ToastrService) { 
    this.createForm();
  }

  createForm() {
    this.EditForm = this.fb.group({      
      name: ['', Validators.required ],
      description: ['', Validators.required ],
      institution_id: ['', Validators.required ],
      contract_id: ['', Validators.required ],
      study_id: ['', Validators.required ],
      jobfunction_id: ['', Validators.required ],
      city_id: ['', Validators.required ],
      experience_id: ['', Validators.required ],
      missions: ['', Validators.required ],
      skills: ['', Validators.required ]
    });
  }

  ngOnInit() {
    
    this.jobService.getInstitutions()
      .subscribe(
        res => this.Institutions = res.data,
        err => console.log(err)
      )
    
    this.jobService.getCities()
      .subscribe(
        res => this.Cities = res.data,
        err => console.log(err)
      )
    
    this.jobService.getJobFunctions()
      .subscribe(
        res => this.Jobfunctions = res.data,
        err => console.log(err)
      )
    
    this.jobService.getExperiences()
      .subscribe(
        res => this.Experiences = res.data,
        err => console.log(err)
      )
    
    this.jobService.getStudies()
      .subscribe(
        res => this.Studies = res.data,
        err => console.log(err)
      )
    
    this.jobService.getContracts()
      .subscribe(
        res => this.Contracts = res.data,
        err => console.log(err)
      ) 
      
    this.route.params.subscribe(params => {
      this.jobService.getJob(params['id']).subscribe(res => {
        this.job = res.data;
        this.loading = false;
      });
    });
    
  }

  EditJob(id) {
    this.jobService.EditJob(JSON.stringify(this.EditForm.value), id)
    .subscribe(
      res => {res.data,
      this.toastr.success("Offre d'emploi est modifié avec succès"),
      this.router.navigate(['/jobs'])
      },
      err => console.log(err)
    )
  } 
}