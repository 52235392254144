import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  selectedEmployees = [];

  showSiblingComp = false;
  
  PartyRoles = [
    {Name: "Vendor",Checked: true},
    {Name: "Client",Checked: true},
    {Name: "Consigner",Checked: false}       
  ]
  constructor() { }

  ngOnInit() {
  }

  editPartyRolesSubmit() {
    console.log(this.PartyRoles);
  }
}
