import { Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { JobService } from '../job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import{ CondidatureService } from '../../condidatures/condidature.service';

@Component({
  selector: 'app-get-job',
  templateUrl: './get-job.component.html',
  styleUrls: ['./get-job.component.css']
})
export class GetJobComponent implements OnDestroy, OnInit {

  job_id:any;city:any;nameJob:any;contract:any;jobfunction:any;institution:any;
  description:any;missions:any;skills:any;study:any;experienceJob:any;created:any;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  Experiences = []; 
  Studies = [];  
  dtOptions: any = {};   
  totalcondidatures:any
  loading:boolean = true;

  constructor(private route: ActivatedRoute, private jobService: JobService, private condidatureService: CondidatureService, private router:Router, private toastr: ToastrService) { 
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.jobService.getJobDetails(params['id']).subscribe(res => {
          this.job_id = res.data[0].id;
          this.nameJob = res.data[0].name;
          this.description = res.data[0].description;
          this.missions = res.data[0].missions;
          this.skills = res.data[0].skills;
          this.city = res.data[0].city.name;
          this.contract = res.data[0].contract.name;
          this.jobfunction = res.data[0].jobfunction.name;
          this.institution = res.data[0].institution.name;
          this.study = res.data[0].study.name;
          this.experienceJob = res.data[0].experience.name;
          this.created = res.data[0].created;
      });
    });    

    this.condidatureService.getExperiences().subscribe(
      res => this.Experiences = res.data,
      err => console.log(err)
    )

    this.condidatureService.getStudies().subscribe(
      res => this.Studies = res.data,
      err => console.log(err)
    )

    this.dtOptions = {
      ajax: (dataTablesParameters: any, callback) => {
        this.route.params.subscribe(params => {
        this.condidatureService.getCondidatureByJob(params['id']).subscribe(res => {
          this.totalcondidatures = res.data.length;
            callback({data: res.data});
            this.loading = false;
          });
        });
      },
      columns: [
      {
        title: 'Nom et prénom',
        data: null
      }, {
        title: "Niveau d'expérience",
        data: 'experience.name'
      }, {
        title: "Niveau d'étude",
        data: 'study.name'
      }, {
        title: 'Email',
        data: 'email'
      }, {
        title: 'Téléphone',
        data: 'phone'
      }, {
        title: 'Date de naissance',
        data: 'bearthday'
      }, {
        title: 'Date de creation',
        data: 'created'
      }, {     
        title: 'Action',
        data: null
      }],
      dom: 'Blfrtip',
      stateSave: true,
      buttons: [
        {
            extend: 'print',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-print"></i> Imprimer',
            exportOptions: {
                columns: [1, 2, 3, 4, 5, 6]
            }
        },
        {
            extend: 'excel',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-download"></i> Exporter en csv',
            exportOptions: {
                columns: [1, 2, 3, 4, 5, 6]
            }
        }
      ],
      columnDefs: [
        {
          targets: [0],
          visible: true,
          data: 'action',
          render: function(data, type, full, meta) {
            return '<td><a class="GetJob" style="cursor: pointer;">'+data.name+'</a></td>'
          }
        },
        {
          targets: [7],
          visible: true,
          data: 'action',
          render: function(data, type, full, meta, condidatures_edit= "'edit'", condidatures_delete= "'delete'") {
            return '<div style="text-align: right;"<td style="text-align: right;"><a class="btn btn-sm btn-secondary btn-icon showcv" style="cursor: pointer;width: auto;font-size: 0.875rem !important;"><i class="fa fa-file-text-o" style="padding-right:4px;"></i> Afficher le CV</a><a class="btn btn-sm btn-success btn-icon GetJob" style="cursor: pointer;"><i class="fa fa-eye"></i></a></td></div>'
          }
        }
      ],
      rowCallback: (row: Node, data: any | Object, index: number) => {
          const self = this;
          const GetJob = $('td', row).find('a.GetJob');
          if (GetJob) {
              GetJob.unbind('click');
              GetJob.bind('click', () => {
                  this.router.navigate(['/condidatures/get/'+data.id])
              });
          }
          const showcv = $('td', row).find('a.showcv');
          if (showcv) {
              showcv.unbind('click');
              showcv.bind('click', () => {
                // window.open('http://localhost/rh/Server/uploads/files/'+data.cv);
                window.open('https://rh.leonafricain.ma/Server/api/uploads/files/'+data.cv);
              });
          }
          return row;
      },
      responsive: true,
      language: {
          lengthMenu: 'Afficher _MENU_ enregistrements par page',
          zeroRecords: 'Aucun offre disponible',
          info: '_START_ à _END_ sur un total de _TOTAL_ enregistrements',
          infoEmpty: 'Aucun offre disponible',
          infoFiltered: '(filtré(s) sur _MAX_ enregistrements)',
          paginate: {
              first:      'Premier',
              last:       'dernier',
              next:       'Suivant',
              previous:   'Precedent'
          },
          search: '_INPUT_',
          searchPlaceholder: 'Recherche',

      }
    };
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();
  }  
  
  ngAfterViewInit(): void {  
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('#name').on('keyup change', function () {
          dtInstance.columns([0]).search(this['value']).draw();
      })  
      $('#experience').on('keyup change', function () {
          dtInstance.columns([1]).search(this['value']).draw();
      })  
      $('#studie').on('keyup change', function () {
          dtInstance.columns([2]).search(this['value']).draw();
      })   
    });  
    
  }
}