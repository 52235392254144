import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './users/login/login.component';
import { RegisterComponent } from './users/register/register.component';
import { GetJobComponent } from './jobs/get/get-job.component';
import { AddJobComponent } from './jobs/add/add-job.component';
import { EditJobComponent } from './jobs/edit/edit-job.component';
import { ListJobComponent } from './jobs/list/list-job.component';
import { AuthGuard } from './auth.guard';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { HeaderComponent } from './header/header.component';
import { AddUserComponent } from './users/add/add-user.component';
import { ListUserComponent } from './users/list/list-user.component';
import { EditUserComponent } from './users/edit/edit-user.component';
import { GetUserComponent } from './users/get/get-user.component';
import { AddRoleComponent } from './roles/add/add-role.component';
import { EditRoleComponent } from './roles/edit/edit-role.component';
import { ListRoleComponent } from './roles/list/list-role.component';
import { GetRoleComponent } from './roles/get/get-role.component';
import { TestComponent } from './test/test.component';
import { AddCondidatureComponent } from './condidatures/add/add-condidature.component';
import { EditCondidatureComponent } from './condidatures/edit/edit-condidature.component';
import { GetCondidatureComponent } from './condidatures/get/get-condidature.component';
import { ListCondidatureComponent } from './condidatures/list/list-condidature.component';


const routes: Routes = [  
  {
    path: '',
    redirectTo: '/jobs',
    pathMatch: 'full',
    //to check token in frontend
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    }
  },
  {
    path: 'jobs',
    component: ListJobComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]

    } 
  },
  {
    path: 'jobs/add',
    component: AddJobComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'jobs/get/:id',
    component: GetJobComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'jobs/edit/:id',
    component: EditJobComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1}]
    } 
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'error',
    component: ErrorpageComponent
  },
  {
    path: 'header',
    component: HeaderComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'user/add',
    component: AddUserComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'users',
    component: ListUserComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'users/get/:id',
    component: GetUserComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'users/edit/:id',
    component: EditUserComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1}]
    } 
  },
  {
    path: 'roles',
    component: ListRoleComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'role/add',
    component: AddRoleComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'role/get/:id',
    component: GetRoleComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'roles/edit/:id',
    component: EditRoleComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1}]
    } 
  },
  {
    path: 'condidatures',
    component: ListCondidatureComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'condidatures/add',
    component: AddCondidatureComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'condidatures/get/:id',
    component: GetCondidatureComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1},{"role_id":2}]
    } 
  },
  {
    path: 'condidatures/edit/:id',
    component: EditCondidatureComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1}]
    } 
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate:[AuthGuard],
    data: { 
      expectedRole: [{"role_id":1}]
    } 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
