import { Component, OnInit } from '@angular/core';
import { RoleService } from '../role.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {

  role:any = {};
  permissions:any = [];
  EditForm: FormGroup;
  result:any = {};
  loading:boolean = true;
  index=false; management_jobs=false; management_condidatures=false; management_interviews=false; management_users=false; roles=false; 
  module_jobs=false; module_configs=false; module_reports=false; module_dashboards=false; 

  constructor(private route: ActivatedRoute,private fb: FormBuilder, private roleService: RoleService, private router:Router, private toastr: ToastrService) {
    this.createForm();
  }

  createForm() {
    this.EditForm = this.fb.group({
      name: ['', Validators.required ]
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.roleService.GetRole(params['id']).subscribe(res => {
        this.role = res.data;
        this.checkPermission();
        this.loading = false;
      });
    });
  }

  checkPermission() {
    this.route.params.subscribe(params => {
      this.roleService.GetRole(params['id']).subscribe(res => {
        this.permissions = JSON.parse(res.data.permissions);
        if (this.permissions.find(x => x === "index")) { this.index=true }
        if (this.permissions.find(x => x === "jobs_add")) { this.management_jobs=true }
        if (this.permissions.find(x => x === "condidatures_add")) { this.management_condidatures=true }
        if (this.permissions.find(x => x === "interviews_add")) { this.management_interviews=true }
        if (this.permissions.find(x => x === "module_jobs")) { this.module_jobs=true }
        if (this.permissions.find(x => x === "module_configs")) { this.module_configs=true }
        if (this.permissions.find(x => x === "module_reports")) { this.module_reports=true }
        if (this.permissions.find(x => x === "module_dashboards")) { this.module_dashboards=true }
        if (this.permissions.find(x => x === "management_users")) { this.management_users=true }
        if (this.permissions.find(x => x === "roles")) { this.roles=true }
      });
    });
  }

  selectedPermission(event, val){
    if (event.target.checked) {
      this.permissions.push(val);
    }else{
      var index = this.permissions.indexOf(val);
      this.permissions.splice(index, 1);  
    }       
  }

  EditPermissions(id){
    this.result = ({ "name":this.EditForm.value.name,
                  "permissions":JSON.stringify(this.permissions)})

    this.roleService.EditRole(this.result, id)
    .subscribe( 
      res => {res.data,
      this.toastr.success("role est modifié avec succès"),
      this.router.navigate(['/roles'])
      },
      err => console.log(err)
    )

  }

}
