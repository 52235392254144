import { Component, OnInit } from '@angular/core';
import { RoleService } from '../role.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

  AddForm: FormGroup;
  permissions:any = [];
  result:any;

  constructor(private route: ActivatedRoute,private fb: FormBuilder, private roleService: RoleService, private router:Router, private toastr: ToastrService) { 
    this.createForm();    
  }

  ngOnInit() {
  }

  createForm() {
    this.AddForm = this.fb.group({
      name: ['', Validators.required ]
    });
  }

  selectedPermission(event, val){
    if (event.target.checked) {
        if(val == 'management_jobs'){
          this.permissions.push('jobs_add','jobs_edit','jobs_delete');
        } else if (val == 'management_condidatures'){
          this.permissions.push('condidatures_add','condidatures_edit','condidatures_delete');
        } else if (val == 'management_interviews'){
          this.permissions.push('interviews_add','interviews_edit','interviews_delete');
        } else {
          this.permissions.push(val);
        }

    }else{
      
      if(val == 'management_jobs'){
        var index = this.permissions.indexOf('jobs_add');
        this.permissions.splice(index, 1); 
        var index = this.permissions.indexOf('jobs_edit');
        this.permissions.splice(index, 1); 
        var index = this.permissions.indexOf('jobs_delete');
        this.permissions.splice(index, 1); 

      }else if(val == 'management_condidatures'){
        var index = this.permissions.indexOf('condidatures_add');
        this.permissions.splice(index, 1); 
        var index = this.permissions.indexOf('condidatures_edit');
        this.permissions.splice(index, 1); 
        var index = this.permissions.indexOf('condidatures_delete');
        this.permissions.splice(index, 1); 

      }else if(val == 'management_interviews'){
        var index = this.permissions.indexOf('interviews_add');
        this.permissions.splice(index, 1); 
        var index = this.permissions.indexOf('interviews_edit');
        this.permissions.splice(index, 1); 
        var index = this.permissions.indexOf('interviews_delete');
        this.permissions.splice(index, 1); 

      }else{
          var index = this.permissions.indexOf(val);
          this.permissions.splice(index, 1);  
        }
    }       
  }

  AddRole(){
    this.AddForm.value.statut = 1;
    this.result = ({ "name":this.AddForm.value.name,
                  "permissions":JSON.stringify(this.permissions)})

    this.roleService.AddRole(this.result)
    .subscribe( 
      res => {res.data,
      this.toastr.success("role est enregistrée avec succès"),
      this.router.navigate(['/roles'])
      },
      err => console.log(err)
    )

  }
}
