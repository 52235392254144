import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CondidatureService } from '../condidature.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  })
};

@Component({
  selector: 'app-add-condidature',
  templateUrl: './add-condidature.component.html',
  styleUrls: ['./add-condidature.component.css']
})
export class AddCondidatureComponent implements OnInit {
  // SERVER_URL = 'http://localhost/rh/Server/api/users/uploadFile'
  SERVER_URL = 'https://rh.leonafricain.ma/Server/api/users/uploadFile'
  Experiences = [];
  Studies = [];
  Jobs = [];
  fileData: File = null;
  AddForm: FormGroup;
  loading:boolean = true;
  cv_label:string;
  fileExtensionMessage:string;
  errorMessage:boolean = false;

  constructor(private http: HttpClient, private fb: FormBuilder, private condidatureService: CondidatureService, private router:Router, private toastr: ToastrService) { 
    this.createForm();
  }

  createForm() {
    this.AddForm = this.fb.group({
      job_id: ['', Validators.required ],
      name: ['', Validators.required ],
      email: ['', Validators.required ],
      phone: ['', Validators.required ],
      bearthday: ['', Validators.required ],
      study_id: ['', Validators.required ],
      last_training: ['', Validators.required ],
      experience_id: ['', Validators.required ],
      skills: ['', Validators.required ],
      cv: ['', Validators.required ],
      active: ['1', Validators.required ]
    });
  }

  ngOnInit() {
    this.cv_label = 'Choisir un fichier PDF/DOCX ';
    this.condidatureService.getJobsByStatut(1)
      .subscribe(
        res => this.Jobs = res.data,
        err => console.log(err)
      )
    
      this.condidatureService.getExperiences()
        .subscribe(
          res => this.Experiences = res.data,
          err => console.log(err)
        )
    
    this.condidatureService.getStudies()
      .subscribe(
        res => {this.Studies = res.data,
                this.loading = false},
        err => console.log(err)
      )
  }

  AddCondidature() {    
    let microtime = Date.now();
    let extension = this.fileData.name.split('.').pop();
    this.AddForm.value.cv = microtime+'.'+extension;    
    this.condidatureService.AddCondidature(JSON.stringify(this.AddForm.value))
    .subscribe(
      res => {res.data,
        this.UploadFile(microtime);
      this.toastr.success("Condidature est enregistré avec succès"),
      this.router.navigate(['/condidatures'])
      },
      err => {console.log(err)
      this.toastr.error("Adresse e-mail est déjà utilisée")
      }
    )
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.cv_label = this.fileData.name    
    var allowedExtensions = ["pdf","docx"];
    let fileExtension = this.cv_label.split('.').pop();

    if(this.isInArray(allowedExtensions, fileExtension)) {
        this.fileExtensionMessage = "";
        this.errorMessage = false;
    } else {
        this.fileExtensionMessage = "Veuillez choisir un fichier PDF ou DOCX !";
        this.errorMessage = true;
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  UploadFile($microtime) {    
    const formData = new FormData();
    formData.append('cv', this.fileData);
    this.http.post<any>(this.SERVER_URL+'/'+$microtime, formData, httpOptions)
      .subscribe(
            (res) => console.log(res),
            (err) => console.log(err))
  }
  
}