import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer} from '@angular/core';
import{ UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent implements OnDestroy, OnInit {
  
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  Roles = []; 
  dtOptions: any = {};    
  @Input() user_id: string;
  @Input() user_title: string;
  user_data = [];
  loading:boolean = true;

  constructor(private route: ActivatedRoute, private router:Router, private userService: UserService, private toastr: ToastrService, private renderer:Renderer) { }

  ngOnInit() {    

    this.userService.getRoles().subscribe(
      res => this.Roles = res.data,
      err => console.log(err)
    )

    this.dtOptions = {
      ajax: (dataTablesParameters: any, callback) => {
        this.userService.getUsersDetails().subscribe(res => {
            callback({data: res.data});
            this.loading = false;
        });
      },
      columns: [
      {
        title: 'Identifiant',
        data: null
      },{
        title: 'Roles',
        data: 'role.name'
      },{
        title: 'Date de creation',
        data: 'created'
      },{
        title: 'Date de modification',
        data: 'modified'
      },{     
        title: 'Action',
        data: null
      }],
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      stateSave: true,
      buttons: [
        {
            extend: 'print',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-print"></i> Imprimer',
            exportOptions: {
                columns: [1, 2, 3]
            }
        },
        {
            extend: 'excel',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-download"></i> Exporter en csv',
            exportOptions: {
                columns: [1, 2, 3]
            }
        }
      ],
      columnDefs: [
          // {
          //     targets: [2],
          //     visible: true,
          //     data: 'action',
          //     render: function(data, type, full, meta, active= data.active) {
          //     return '<div> <i *ngIf="'+active+'=== 1" class="fa fa-check" style="color: green"></i><i *ngIf ==="'+active+'=0" class="fa fa-trash" style="color: red"></i></div>'
          //     }
          // },
          {
            targets: [0],
            visible: true,
            data: 'action',
            render: function(data, type, full, meta) {
            return '<td><a class="GetUser" style="cursor: pointer;">'+data.username+'</a></td>'
            }
          },
          {
              targets: [4],
              visible: true,
              data: 'action',
              render: function(data, type, full, meta) {
               return '<div style="text-align: right;"<td style="text-align: right;"><a class="btn btn-sm btn-success btn-icon GetUser" style="cursor: pointer;"><i class="fa fa-eye"></i></a><a class="btn btn-sm btn-warning btn-icon EditUser" style="cursor: pointer;"><i class="fa fa-edit"></i></a><a class="btn btn-sm btn-danger btn-icon RemoveUser" style="cursor: pointer;"><i class="fa fa-trash"></i></a></td></div>'
              }
          }
      ],
      rowCallback: (row: Node, data: any | Object, index: number) => {
          const self = this;
          const GetUser = $('td', row).find('a.GetUser');
          if (GetUser) {
              GetUser.unbind('click');
              GetUser.bind('click', () => {
                  this.router.navigate(['/users/get/'+data.id])
              });
          }
          const EditUser = $('td', row).find('a.EditUser');
          if (EditUser) {
              EditUser.unbind('click');
              EditUser.bind('click', () => {
                  this.router.navigate(['/users/edit/'+data.id])
              });
          }
          const RemoveUser = $('td', row).find('a.RemoveUser');
          if (RemoveUser) {
              RemoveUser.unbind('click');
              RemoveUser.bind('click', () => {
                  this.showDeleteModal(data.id, data.username, data)
              });
          }
          return row;
      },
      responsive: true,
      language: {
          lengthMenu: 'Afficher _MENU_ enregistrements par page',
          zeroRecords: 'Aucun offre disponible',
          info: '_START_ à _END_ sur un total de _TOTAL_ enregistrements',
          infoEmpty: 'Aucun offre disponible',
          infoFiltered: '(filtré(s) sur _MAX_ enregistrements)',
          paginate: {
              first:      'Premier',
              last:       'dernier',
              next:       'Suivant',
              previous:   'Precedent'
          },
          search: '_INPUT_',
          searchPlaceholder: 'Recherche',

      }
    };
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();
  }  
  
  ngAfterViewInit(): void {  
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('#name').on('keyup change', function () {
          dtInstance.columns([0]).search(this['value']).draw();
      })  
      $('#role').on('keyup change', function () {
          dtInstance.columns([1]).search(this['value']).draw();
      })   
    });  
  }

  showDeleteModal(id,title,data): void {
    this.user_id = id;
    this.user_title = title;
    this.user_data = data;
    document.getElementById("show_modal").click();
  }

  DeleteUser(id, data) {
    data.active = 0;
    this.userService.EditUser(data, id).subscribe(
      res => {
        this.ngOnInit();
        document.getElementById("close_modal").click();
        this.toastr.success("Utilisateur est supprimé avec succès")
        this.router.navigate(['/users'])
      },
      err => console.log(err)
    )
  }

}
