import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  // registerUserData = {}
  registerUserData: FormGroup;
  constructor(private auth: AuthService, private router:Router, private toastr: ToastrService, private fb: FormBuilder) { 
    this.createForm();
  }

  createForm() {
    this.registerUserData = this.fb.group({
      username: ['', Validators.required ],
      role_id: ['2', Validators.required ],
      password: ['', Validators.required ]
    });
  }

  ngOnInit() {
  }

  registerUser() {
    // console.log(this.registerUserData);
    this.auth.registerUser(JSON.stringify(this.registerUserData.value))
    .subscribe(
      res => {console.log(res.data),
      // localStorage.setItem('token', res.data.token),
      this.toastr.success('Vous êtes enregistré avec succès !'),
      this.router.navigate(['/login'])
      },
      err => console.log(err)
    ) 
  }
}
