import { Component, OnInit } from '@angular/core';
import { CondidatureService } from '../condidature.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-get-condidature',
  templateUrl: './get-condidature.component.html',
  styleUrls: ['./get-condidature.component.css']
})
export class GetCondidatureComponent implements OnInit {

  condidature:any = {};
  loading:boolean = true;

  constructor(private route: ActivatedRoute, private condidatureService: CondidatureService, private router:Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.condidatureService.getCondidatureDetails(params['id']).subscribe(res => {
          this.condidature = res.data[0];
          this.loading = false;
      });
    });
  }

}