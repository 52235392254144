import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // loginUserData = {}
  loginUserData: FormGroup;

  constructor(private auth:AuthService, private router:Router, private toastr: ToastrService, private fb: FormBuilder) {
    this.createForm();
  }

  createForm() {
    this.loginUserData = this.fb.group({
      username: ['', Validators.required ],
      password: ['', Validators.required ]
    });
  }
  ngOnInit() {
  }

  loginuser () {
    this.auth.loginUser(JSON.stringify(this.loginUserData.value))
    .subscribe(
      res => {res.data,
        localStorage.setItem('token', res.data.token),
        localStorage.setItem('currentUser', res.data.username),
        this.router.navigate(['/jobs'])
        },
      err => {console.log(err),
      this.toastr.error("Nom d'utilisateur ou le mot de passe est incorrect !")
      }
    )
  }
}
