import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  getUsername: any
  loading:boolean = true;
  
  constructor(private authService: AuthService) { }

  ngOnInit() {
    // load permissions  
    this.authService.LoadPermissions();   
    this.getUsername = this.authService.getUsername();
  }
  logoutUser(){
    this.authService.logoutUser()
  }
}