import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import decode from 'jwt-decode';
import {SlimLoadingBarService} from 'ng2-slim-loading-bar';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  })
};
  
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  Permissions: any;

  // private usersUrl = "http://localhost/rh/Server/api/users";
  // private RolesUrl = "http://localhost/rh/Server/api/roles";
  private usersUrl = "https://rh.leonafricain.ma/Server/api/users";
  private RolesUrl = "https://rh.leonafricain.ma/Server/api/roles";

   constructor(private http: HttpClient, private router:Router,private permissionsService: NgxPermissionsService, private slimLoadingBarService: SlimLoadingBarService) { }

  registerUser(user) {
    return this.http.post<any>(this.usersUrl+"/register", user, httpOptions)
  }

  loginUser(user) {
    return this.http.post<any>(this.usersUrl+"/login", user, httpOptions)
  }
  
  loggedIn() {
    return !!localStorage.getItem('token')
  }

  logoutUser() {
    localStorage.removeItem('token')
    localStorage.removeItem('currentUser')
    this.router.navigate(['/login'])
  }

  getToken() {
    return localStorage.getItem('token') 
  }

  getUsername() {
    return localStorage.getItem('currentUser');
  }

  getPermissions(role_id) {
    return this.http.get<any>(this.RolesUrl+"/"+role_id, httpOptions)
  }

  LoadPermissions(){
    //get role id from token
    var token = localStorage.getItem('token');
    var tokenPayload = decode(token);
    // load permissions
    this.getPermissions(tokenPayload.role_id).subscribe((res) => {
      var myData = res.data.permissions;
      this.Permissions = JSON.parse(myData);
      this.permissionsService.loadPermissions(this.Permissions);  
    });
  }
}