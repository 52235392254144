import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // private Url = "http://localhost/rh/Server/api";
  private Url = "https://rh.leonafricain.ma/Server/api";

  constructor(private http: HttpClient) { }

  getUsers() {
    return this.http.get<any>(this.Url+"/users", httpOptions)
  }
  getUser(id){
    return this.http.get<any>(this.Url+"/users/"+id, httpOptions)
  }
  EditUser(user, id){
    return this.http.post<any>(this.Url+"/users/edit/"+id, user, httpOptions)
  }
  deleteUser(id){
    return this.http.post<any>(this.Url+"/users/delete/"+id, httpOptions)
  }
  getRoles(){
    return this.http.get<any>(this.Url+"/roles", httpOptions)
  }
  getUsersDetails(){
    return this.http.get<any>(this.Url+"/users/details", httpOptions)
  }
  getUserDetails(id){
    return this.http.get<any>(this.Url+"/users/details/"+id, httpOptions)
  }
}
