import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-get-user',
  templateUrl: './get-user.component.html',
  styleUrls: ['./get-user.component.css']
})
export class GetUserComponent implements OnInit {

  user:any = {};
  loading:boolean = true;

  constructor(private route: ActivatedRoute, private userService: UserService, private router:Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.userService.getUserDetails(params['id']).subscribe(res => {
          this.user = res.data[0];
          this.loading = false;
      });
    });
  }

}
