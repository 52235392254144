import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer} from '@angular/core';
import{ CondidatureService } from '../condidature.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-list-condidature',
  templateUrl: './list-condidature.component.html',
  styleUrls: ['./list-condidature.component.css']
})
export class ListCondidatureComponent implements OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  Experiences = []; 
  Studies = [];  
  Jobs = [];  
  dtOptions: any = {};    
  @Input() condidature_id: string;
  @Input() condidature_title: string;
  condidature_data = [];
  loading:boolean = true;

  constructor(private router:Router, private condidatureService: CondidatureService, private toastr: ToastrService) { }

  ngOnInit(): void {

    this.condidatureService.getExperiences().subscribe(
      res => this.Experiences = res.data,
      err => console.log(err)
    )

    this.condidatureService.getStudies().subscribe(
      res => this.Studies = res.data,
      err => console.log(err)
    )

    this.condidatureService.getJobsByStatut(1).subscribe(
      res => this.Jobs = res.data,
      err => console.log(err)
    )

      
    this.dtOptions = {
      ajax: (dataTablesParameters: any, callback) => {
        this.condidatureService.getCondidaturesDetails().subscribe(res => {
            callback({data: res.data});
            this.loading = false;
        });
      },
      columns: [
      {
        title: 'Nom et prénom',
        data: null
      }, {
        title: "Offre d'emploi",
        data: null
      }, {
        title: "Niveau d'expérience",
        data: 'experience.name'
      }, {
        title: "Niveau d'étude",
        data: 'study.name'
      }, {
        title: 'Email',
        data: 'email'
      }, {
        title: 'Téléphone',
        data: 'phone'
      }, {
        title: 'Date de naissance',
        data: 'bearthday'
      }, {
        title: 'Date de creation',
        data: 'created'
      }, {     
        title: 'Action',
        data: null
      }],
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      stateSave: true,
      buttons: [
        {
            extend: 'print',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-print"></i> Imprimer',
            exportOptions: {
                columns: [1, 2, 3, 4, 5, 6, 7]
            }
        },
        {
            extend: 'excel',
            className: 'btn btn-sm btn-secondary',
            text: '<i class="fa fa-download"></i> Exporter en csv',
            exportOptions: {
                columns: [1, 2, 3, 4, 5, 6, 7]
            }
        }
      ],
      createdRow: function(row, data, dataIndex) {
      // $compile(angular.element(row).contents())($scope);
      },
      columnDefs: [
        {
          targets: [0],
          visible: true,
          data: 'action',
          render: function(data, type, full, meta) {
            return '<td><a class="GetCondidature" style="cursor: pointer;">'+data.name+'</a></td>'
          }
        },
        {
          targets: [1],
          visible: true,
          data: 'action',
          render: function(data, type, full, meta) {
            return '<td><a class="GetCondidatureJob" style="cursor: pointer;">'+data.job.name+'</a></td>'
          }
        },
        {
          targets: [8],
          visible: true,
          data: 'action',
          render: function(data, type, full, meta, condidatures_edit= "'condidatures_edit'", condidatures_delete= "'condidatures_delete'") {
            return '<div style="text-align: right;"<td style="text-align: right;"><a class="btn btn-sm btn-secondary btn-icon showcv" style="cursor: pointer;width: auto;"><i class="fa fa-file-text-o" style="padding-right:4px;"></i> Afficher le CV</a><a class="btn btn-sm btn-success btn-icon GetCondidature" style="cursor: pointer;"><i class="fa fa-eye"></i></a><a class="btn btn-sm btn-danger btn-icon RemoveCondidature" *ngxPermissionsOnly="['+condidatures_delete+']" style="cursor: pointer;"><i class="fa fa-trash"></i></a></td></div>'
          }
        }
      ],
      rowCallback: (row: Node, data: any | Object, index: number) => {
          const self = this;
          const GetCondidature = $('td', row).find('a.GetCondidature');
          if (GetCondidature) {
              GetCondidature.unbind('click');
              GetCondidature.bind('click', () => {
                  this.router.navigate(['/condidatures/get/'+data.id])
              });
          }
          const showcv = $('td', row).find('a.showcv');
          if (showcv) {
              showcv.unbind('click');
              showcv.bind('click', () => {                
                window.open('https://rh.leonafricain.ma/Server/api/uploads/files/'+data.cv);
                // window.open('http://localhost/rh/Server/uploads/files/'+data.cv);
              });
          }
          const GetCondidatureJob = $('td', row).find('a.GetCondidatureJob');
          if (GetCondidatureJob) {
              GetCondidatureJob.unbind('click');
              GetCondidatureJob.bind('click', () => {
                  this.router.navigate(['/jobs/get/'+data.job_id])
              });
          }
          const RemoveCondidature = $('td', row).find('a.RemoveCondidature');
          if (RemoveCondidature) {
              RemoveCondidature.unbind('click');
              RemoveCondidature.bind('click', () => {
                  this.showDeleteModal(data.id, data.name, data)
              });
          }
          return row;
      },
      responsive: true,
      language: {
          lengthMenu: 'Afficher _MENU_ enregistrements par page',
          zeroRecords: 'Aucun offre disponible',
          info: '_START_ à _END_ sur un total de _TOTAL_ enregistrements',
          infoEmpty: 'Aucun offre disponible',
          infoFiltered: '(filtré(s) sur _MAX_ enregistrements)',
          paginate: {
              first:      'Premier',
              last:       'dernier',
              next:       'Suivant',
              previous:   'Precedent'
          },
          search: '_INPUT_',
          searchPlaceholder: 'Recherche',

      }
    };
  }


  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();
  }
  
  
  ngAfterViewInit(): void {  
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('#name').on('keyup change', function () {
          dtInstance.columns([0]).search(this['value']).draw();
      })  
      $('#job').on('keyup change', function () {
          dtInstance.columns([1]).search(this['value']).draw();
      }) 
      $('#experience').on('keyup change', function () {
          dtInstance.columns([2]).search(this['value']).draw();
      })  
      $('#studie').on('keyup change', function () {
          dtInstance.columns([3]).search(this['value']).draw();
      })   
    });  
    
  }

  showDeleteModal(id,title, data): void {
    this.condidature_id = id;
    this.condidature_title = title;
    this.condidature_data = data;
    document.getElementById("show_modal").click();
  }

  DeleteCondidature(id, data) {
    data.active = 0;
    this.condidatureService.EditCondidature(data, id).subscribe(
      res => {
        this.ngOnInit();
        document.getElementById("close_modal").click();
        this.toastr.success("Condidature est supprimé avec succès")
        this.router.navigate(['/condidatures'])
      },
      err => console.log(err)
    )
  }
  
}