import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './users/register/register.component';
import { LoginComponent } from './users/login/login.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { TokenInterceptorService } from './token-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { GetJobComponent } from './jobs/get/get-job.component';
import { AddJobComponent } from './jobs/add/add-job.component';
import { EditJobComponent } from './jobs/edit/edit-job.component';
import { ListJobComponent } from './jobs/list/list-job.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { HeaderComponent } from './header/header.component';
import {SlimLoadingBarModule} from 'ng2-slim-loading-bar';
import { AddUserComponent } from './users/add/add-user.component';
import { EditUserComponent } from './users/edit/edit-user.component';
import { GetUserComponent } from './users/get/get-user.component';
import { ListUserComponent } from './users/list/list-user.component';
import { AddRoleComponent } from './roles/add/add-role.component';
import { EditRoleComponent } from './roles/edit/edit-role.component';
import { ListRoleComponent } from './roles/list/list-role.component';
import { GetRoleComponent } from './roles/get/get-role.component';
import { TestComponent } from './test/test.component';
import { AddCondidatureComponent } from './condidatures/add/add-condidature.component';
import { EditCondidatureComponent } from './condidatures/edit/edit-condidature.component';
import { GetCondidatureComponent } from './condidatures/get/get-condidature.component';
import { ListCondidatureComponent } from './condidatures/list/list-condidature.component';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    GetJobComponent,
    AddJobComponent,
    EditJobComponent,
    ListJobComponent,
    ErrorpageComponent,
    HeaderComponent,
    AddUserComponent,
    EditUserComponent,
    GetUserComponent,
    ListUserComponent,
    AddRoleComponent,
    EditRoleComponent,
    ListRoleComponent,
    GetRoleComponent,
    TestComponent,
    AddCondidatureComponent,
    EditCondidatureComponent,
    GetCondidatureComponent,
    ListCondidatureComponent
  ],
  imports: [
    NgxPermissionsModule.forRoot(),
    BrowserModule, 
    FormsModule,
    HttpClientModule,
    AppRoutingModule,  
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DataTablesModule,
    ToastrModule.forRoot(),
    SlimLoadingBarModule.forRoot()
  ],
  providers: [AuthService, AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
