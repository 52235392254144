import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  // private Url = "http://localhost/rh/Server/api";
  private Url = "https://rh.leonafricain.ma/Server/api";

  constructor(private http: HttpClient) { }  

  AddRole(role){
    return this.http.post<any>(this.Url+"/roles/add", role, httpOptions)
  }
  GetRoles(){
    return this.http.get<any>(this.Url+"/roles", httpOptions)
  }
  GetRole(id){
    return this.http.get<any>(this.Url+"/roles/"+id, httpOptions)
  }
  EditRole(role, id){
    return this.http.post<any>(this.Url+"/roles/edit/"+id, role, httpOptions)
  }
  deleteRole(id){
    return this.http.post<any>(this.Url+"/roles/delete/"+id, httpOptions)
  }
}

