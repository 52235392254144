import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class JobService {

  // private Url = "http://localhost/rh/Server/api";
  private Url = "https://rh.leonafricain.ma/Server/api";

  constructor(private http: HttpClient) { }

  getJobs() {
    return this.http.get<any>(this.Url+"/jobs", httpOptions)
  }
  AddJob(job){
    return this.http.post<any>(this.Url+"/jobs/add", job, httpOptions)
  }
  getJob(id){
    return this.http.get<any>(this.Url+"/jobs/"+id, httpOptions)
  }
  getJobsDetails(){
    return this.http.get<any>(this.Url+"/jobs/Details", httpOptions)
  }
  getJobDetails(id=null){
    return this.http.get<any>(this.Url+"/jobs/Details/"+id, httpOptions)
  }
  EditJob(job, id){
    return this.http.post<any>(this.Url+"/jobs/edit/"+id, job, httpOptions)
  }
  deleteJob(id){
    return this.http.post<any>(this.Url+"/jobs/delete/"+id, httpOptions)
  }
  getInstitutions(){
    return this.http.get<any>(this.Url+"/institutions", httpOptions)
  }
  getCities(){
    return this.http.get<any>(this.Url+"/cities", httpOptions)
  }
  getJobFunctions(){
    return this.http.get<any>(this.Url+"/jobfunctions", httpOptions)
  }
  getExperiences(){
    return this.http.get<any>(this.Url+"/experiences", httpOptions)
  }
  getStudies(){
    return this.http.get<any>(this.Url+"/studies", httpOptions)
  }
  getOccupations(){
    return this.http.get<any>(this.Url+"/occupations", httpOptions)
  }
  getContracts(){
    return this.http.get<any>(this.Url+"/contracts", httpOptions)
  }
}